<template>
  <div />
</template>
<script>
import { AUTH_TOKEN } from '../../constants/storage';
import { logout } from '../../utils/session';

export default {
  data() {
    return {
      interval: null,
      REFRESH_INTERVAL: 300000, // Five minutes
    };
  },
  methods: {
    parseJwt(token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join('')
      );

      return JSON.parse(jsonPayload);
    },
    canRefresh() {
      const token = localStorage.getItem(AUTH_TOKEN);
      const parsed = this.parseJwt(token);
      const { exp } = parsed;
      const now = this.$store.getters.toTimezone().unix();
      return exp > now;
    },
    checkToken() {
      const token = localStorage.getItem(AUTH_TOKEN);
      const { exp } = this.parseJwt(token);
      const now = this.$store.getters.toTimezone().unix();
      if (now > exp) logout(true);
    },
    async refreshToken() {
      if (!this.canRefresh()) {
        logout(true);
        return;
      }
      try {
        const token = localStorage.getItem(AUTH_TOKEN);
        const { data } = await this.$apollo.mutate({
          mutation: await this.$store.getters.getMutation('RefreshToken'),
          variables: { token },
        });
        const { refreshToken } = data || {};
        localStorage.setItem(AUTH_TOKEN, refreshToken);
      } catch (e) {
        console.error('error refreshing token: ', e);
      }
    },
  },
  mounted() {
    this.interval = setInterval(() => {
      this.checkToken();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>
